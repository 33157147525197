/**********************************
	Imports
**********************************/

import anime from 'animejs/lib/anime.es.js';



/**********************************
	Nav
**********************************/

var burger = document.getElementById('burger');
var nav = document.getElementById('nav');
var title = document.getElementById('title');

if (burger) {
	burger.addEventListener('click', function() {
		nav.classList.toggle("nav--active");
		burger.classList.toggle("burger--active");
		title.classList.toggle("title--disabled");
	})
}



/**********************************
	Page transition
**********************************/

addEventListener('load', function(){
    var internalLinks = document.getElementsByClassName('internal-link');
	
	for (var i=0; i<internalLinks.length; i++) {
		const linkHref = internalLinks[i].href;		

        internalLinks[i].addEventListener('click', function(event) {
			event.preventDefault();

			document.querySelector('.main').classList.add('main--faded');
			document.querySelector('.main').classList.remove('main--loaded');
			
			setTimeout(function () {
				window.location.href = linkHref;
			}, 1000);
        });
    }
}); 


var mainLegal = document.querySelector('.main--legal')

if (mainLegal) {
	setTimeout(function () {
		document.querySelector('.main--legal').classList.add('main--loaded');
	}, 300);
}



/**********************************
	Title animation
**********************************/

var textWrapperList = document.querySelectorAll('.title__line');
var textWrapperArray = [...textWrapperList];

textWrapperArray.forEach(textWrapper => {
	textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
});

anime.timeline({loop: false})
	.add({
		targets: '.title__line--1 .letter',
		opacity: [0,1],
		easing: "easeInOutQuad",
		duration: 800,
		delay: (el, i) => 50 * (i+1)
	})
	.add({
		targets:  '.title__line--2 .letter',
		opacity: [0,1],
		easing: "easeInOutQuad",
		duration: 800,
		delay: (el, i) => 50 * (i+1)
	})


var logo = document.querySelector('.logo')
if (logo) {
	document.querySelector('.logo').classList.add('loaded');
}

if (nav) {
	nav.classList.add('loaded');
}

var close = document.querySelector('.close')
if (close) {
	setTimeout(function () {
		document.querySelector('.close').classList.add('close--loaded');
	}, 500);
}
